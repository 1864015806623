.profile {
  &__user-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: rem(70px);
    height: rem(70px);
    border-radius: rem(50px);
    background-color: $primary-color;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: 2px solid #f5f5f5;
  }

  &__user-title {
    font-size: rem(15px);
    font-weight: 600;

    &-short {
      font-size: rem(22px);
      font-weight: 700;
      color: $white;
    }
  }

  &__user-subtitle {
    font-weight: 500;
    font-size: rem(12px);
    color: $primary-color;
  }
}

.user-details {
  // Description
  &__description {
    padding-top: rem(20px);

    &__label {
      font-size: rem(12px);
      font-weight: 600;
      color: $text-color;
      margin-bottom: rem(8px);
    }
    &__value {
      font-size: rem(14px);
      font-weight: 500;
      color: $text-color;
    }

    &--heading {
      padding-top: rem(20px);
      padding-bottom: rem(20px);
      border-right: 1px solid $border-color;

      .user-details__description__label {
        font-size: rem(14px);
        font-weight: 600;
        color: #6f747e;
      }
      .user-details__description__value {
        font-size: rem(18px);
        font-weight: 600;
      }
    }
  }

  &__description__row {
    margin: 0;
    padding: 0 rem($gutter-size);

    .col:last-child .user-details__description--heading {
      @media (min-width: 1137px) {
        border-right: 0;
      }
    }
  }

  &__description__row:last-child .user-details__description {
    padding-bottom: rem(20px);
  }

  &__description__row .flex-grow {
    @media (min-width: 1137px) {
      flex-grow: 1;
    }
  }
}
