.drop-zone {
  border: 2px dashed $dropzone-border-color;
  background-color: $dropzone-background;
  border-radius: rem(4px);
  position: relative;
  padding: rem(30px);
  margin-bottom: rem(20px);

  &__upload-icon {
    color: $primary-color;
    text-align: center;
    margin-bottom: rem(10px);
  }

  &__drag-drop {
    text-align: center;
    margin-bottom: rem(10px);
  }

  &__or {
    color: $primary-color;
    text-align: center;
    margin-bottom: rem(10px);
  }

  &__browse-file {
    width: rem(150px);
    margin: auto;
    display: block;

    &__icon {
      width: rem(20px);
      height: rem(20px);
    }
  }
}
