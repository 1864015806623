.user-profile-tooltip {
  &__header {
    font-size: rem(12px);
    color: $grey-400;
    padding: rem(10px 15px);
  }

  &__account-list {
    max-height: rem(300px);
    overflow-y: scroll;
    padding: rem(5px 0);

    &__item {
      display: block;
      font-size: rem(14px);
      border-bottom: rem(1px) solid $border-color;
      padding: rem(5px);

      img {
        width: rem(15px);
        margin-right: rem(10px);
      }
    }
  }

  &__profile-management {
    &__item {
      @include flex-center-vert;
      color: $ahref-color;
      font-size: rem(12px);
      padding: rem(10px 15px);
      cursor: pointer;

      svg {
        font-size: rem(16px);
        margin-right: rem(15px);
        width: rem(18px);
      }
    }
    &__item:hover {
      color: $primary-color;
    }
  }
}
