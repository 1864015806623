.textarea {
  width: rem(660px);
  border-radius: rem(4px);
}

.total-users {
  font-size: rem(15px);
  font-weight: 600;
}
.date-picker {
  .ebs-datepicker__wrapper .react-datepicker__input-container {
    margin-top: rem(10px);
  }
}
