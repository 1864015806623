.forbidden-error {
  margin-top: rem(50px);
  margin-bottom: rem(100px);
  &__title {
    color: $primary-color;
    font-size: rem(60px);
  }
  &__description {
    font-size: rem(20px);
    color: $blue;
  }
}
