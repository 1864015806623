// Main colors
$primary-color: #0c7bc9;
$success-color: #17aa51;
$info-color: #3bc0f9;
$warning-color: #ffd83d;
$danger-color: #dd1a1e;
$normal-color: #a5a5a5;

$white: #fff;
$black: #000;
$blue: #05245b;
$sidebar-text-color: #202531;

$dropzone-border-color: $blue;
$dropzone-background: #e6f0ed;
// Accent colors
$accent-color-1: #9b1dd9;
$accent-color-2: #fdce14;
$accent-color-3: #17aa51;

// Primary colors
$primary-000: #eef5ff;
$primary-100: #ccf3fc;
$primary-200: #9be3f9;
$primary-300: #68c6ee;
$primary-400: #41a6de;
$primary-500: $primary-color;
$primary-600: #085fac;
$primary-700: #064790;
$primary-800: #033274;
$primary-900: #022360;

// accent 3 colors
$accent-3-100: #cffacf;
$accent-3-200: #a1f6a9;
$accent-3-300: #6ee585;
$accent-3-400: #47cc6e;
$accent-3-500: $accent-color-3;
$accent-3-600: #109250;
$accent-3-700: #0b7a4d;
$accent-3-800: #076246;
$accent-3-900: #045141;

// Grey colors
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #e5e5e5;
$grey-300: #d5d5d5;
$grey-400: #c5c5c5;
$grey-500: #a5a5a5;
$grey-600: #888888;
$grey-700: #666666;
$grey-800: #444444;
$grey-900: #222222;

// semantic info colors
$info-100: #d7fdfe;
$info-200: #b0f6fe;
$info-300: #89eafd;
$info-400: #6bd9fc;
$info-500: $info-color;
$info-600: #2b97d7;
$info-700: #1d72b3;
$info-800: #11518f;
$info-900: #0c3a76;

$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: #ececec;
$grey-300: #c6c6c6;
$grey-400: #8e8e8e;
$grey-500: #444444;
$grey-600: #3a3132;
$grey-700: #302224;
$grey-800: #27151a;
$grey-900: #200d13;
// Semantic colors
$success-100: #d9fcd4;
$success-200: #adf9aa;
$success-300: #7dee84;
$success-400: #5ade6e;
$success-500: $success-color;
$success-600: #1eac50;
$success-700: #15904c;
$success-800: #0d7445;
$success-900: #086040;

// semantic warning colors
$warning-100: #fffbd8;
$warning-200: #fff4b2;
$warning-300: #ffed8b;
$warning-400: #ffe56e;
$warning-500: $warning-color;
$warning-600: #dbb42b;
$warning-700: #b7921f;
$warning-800: #937212;
$warning-900: #7a5b0b;

// semantic danger colors
$danger-100: #ffe5d5;
$danger-200: #ffc5ac;
$danger-300: #ff9d82;
$danger-400: #ff7863;
$danger-500: $danger-color;
$danger-600: #db2329;
$danger-700: #b7182b;
$danger-800: #930f2a;
$danger-900: #7a0929;

// accent 1 colors
$accent-1-100: #f9d1fd;
$accent-1-200: #efa4fb;
$accent-1-300: #da74f3;
$accent-1-400: #c051e8;
$accent-1-500: $accent-color-1;
$accent-1-600: #7915ba;
$accent-1-700: #5b0e9c;
$accent-1-800: #40097d;
$accent-1-900: #2e0568;

// accent 2 colors
$accent-2-100: #fef9cf;
$accent-2-200: #fef1a0;
$accent-2-300: #fee771;
$accent-2-400: #fdde4e;
$accent-2-500: $accent-color-2;
$accent-2-600: #d9ac0e;
$accent-2-700: #b68c0a;
$accent-2-800: #926d06;
$accent-2-900: #795703;
