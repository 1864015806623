.table-no-border {
  .rc-table-header,
  .rc-table-body,
  .rc-table-content {
    border: 0 !important;
  }
  .rc-table-tbody tr.rc-table-row:last-child td {
    border-bottom: 0 !important;
  }
}

.mw-80 {
  max-width: 80px;
}

.fw {
  &-bold {
    font-weight: bold;
  }
  &-600 {
    font-weight: 600;
  }
}

.fs {
  &-18 {
    font-size: 18px;
  }
  &-14 {
    font-size: 14px;
  }
}

.form-divider {
  margin: rem(20px -30px);
  border-bottom: 1px solid $border-color;
}

.cursor {
  cursor: pointer;
}

.td-center {
  padding-left: rem(60px);
}
.status-type {
  width: rem(80px);
}

.status-type.ebs-label--fill {
  height: rem(25px);
}

.status-type.active {
  background-color: $success-color;
}
.status-type.inactive {
  background-color: $danger-color;
}
.color-text__danger {
  color: $danger-color;
}
