.ebs-sidebar {
  &__item {
    .ebs-icon--user-profile {
      font-size: rem(32px);
      margin-left: rem(-8px);
    }

    &:hover a,
    &.active a {
      color: $white;
    }
  }

  .user-profile {
    svg {
      width: 32px;
      height: 32px;
      margin-left: -5px;
    }
  }
}
