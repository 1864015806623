.banner-form {
  &__box-type {
    .ebs-space__item:first-child {
      width: rem(100px);
    }
    .ebs-space__item:last-child {
      width: 100%;
    }
  }

  &__select-type {
    margin-top: rem(21px);
  }

  &__description {
    font-weight: $field-label-font-weight;
    font-size: rem(16px);
    line-height: rem(24px);
  }

  &__banner {
    height: 200px;
    width: 750px;
    object-fit: contain;
  }

  .upload__container {
    display: none;
  }
}
