@import '~ebs-design/dist/styles/index';

.pagination-wrapper {
  display: flex;

  .pages-counter {
    line-height: rem(25px);
  }

  .label {
    line-height: rem(25px);
  }

  .per-page-wrapper {
    display: flex;
    justify-content: flex-end;

    .per-page {
      margin-left: rem(20px);
    }
  }
}
