.form__box-info {
  max-width: rem(700px);
  padding: rem(0 18px 10px 18px);
}
.permission-text {
  font-size: rem(13px);
  font-weight: 400;
  color: #42526e;
}
.permission-text--checked {
  font-weight: 600;
  color: #141515;
}
