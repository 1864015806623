.container-campaing {
  .message {
    padding: rem(35px);
    font-style: normal;
    font-weight: 500;
    font-size: rem(16px);
    line-height: rem(24px);
  }
  .ebs-card.receivers,
  .ebs-card.date {
    padding: rem(30px);
    width: rem(495px);
  }

  .total-details {
    border-right: 1px solid $border-color;
    h4 {
      font-weight: 600;
      color: #6f747e;
    }
  }
}
