.promotions__image {
  cursor: pointer;
  display: block;
  &-small {
    height: 40px;
  }
  &-medium {
    height: 60px;
  }
}
.modal__image-box {
  @include flex-center-horiz;
}

.modal__image-content {
  display: block;
  height: 100%;
  width: 100%;
}
