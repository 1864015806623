.border-bottom__none.ebs-card__header {
  border-bottom: 0;
}

.bgr-color.ebs-card__body {
  background-color: $white;
}

.categories-box__thumb {
  width: rem(200px);
  height: rem(200px);
  border-radius: rem(4px);
  overflow: hidden;
  position: relative;
}

.categories-box__image {
  display: block;
  width: 100%;
  height: 100%;
}

.categories-box__card.ebs-space {
  position: absolute;
  top: rem(10px);
  padding: rem(0 10px);
}

.card-type {
  @include flex-center;
  height: rem(24px);
  width: rem(24px);
  border: 0;
  border-radius: 50%;
  box-shadow: none;

  &--old {
    background-color: #cbd6e2;
  }

  &--future {
    background-color: #f8c81f;
  }

  &--current {
    background-color: #13bc93;
  }

  &__check.ebs-icon {
    height: rem(12px);
    width: rem(12px);
    color: $white;
  }
}

.box-card-btn {
  @include flex-center;
}

.card-type.category-time {
  width: 100%;
  margin-right: rem(10px);
  padding: rem(0 5px);
  color: $blue;
  background-color: $white;
  border-radius: rem(4px);
  border: 1px solid #d7dae0;
}

.btn-delete.ebs-button__wrapper.ebs-button--medium {
  .ebs-button {
    padding: 0;
    line-height: 0;
  }

  .btn-delete__icon.ebs-icon.ebs-icon--none {
    width: rem(16px);
    height: rem(16px);
    color: $blue;
    transition: color 0.2s ease-in-out;
  }
}
.btn-delete.ebs-button__wrapper.ebs-button--medium:hover,
.btn-delete.ebs-button__wrapper.ebs-button--medium:focus {
  .btn-delete__icon.ebs-icon.ebs-icon--none {
    color: #fff;
  }
}
